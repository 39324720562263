import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { addVisualization } from "../api/requests"

import ReactMarkdown from "react-markdown"
import Moment from "react-moment"

import Layout from "../components/layout"
import LanguageContext from "../wrappers/contexts/LanguageContext"

export const query = graphql`  
  query ArticleQuery($id: String) {
    strapiArticle(strapiId: { eq: $id }) {
      strapiId
      title {
        translate {
           text
           language {
            lang
           }
         }
      }
      content {
        translate {
           content
           language {
            lang
           }
         }
      }
      published_at
      image {
            publicURL
          }
    }
  }
`

const Article = ({ data, pageContext = {} }) => {
  const article = data.strapiArticle
  useEffect(() => {
    addVisualization(article.strapiId)
      .catch(() => console.error("no se envía la visualización"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout seo={{ title: article.title.translate }} urlLang={pageContext.urlLang}>
      <InsideTheLanguageWrapper article={article}/>
    </Layout>
  )
}

const InsideTheLanguageWrapper = ({ article }) => {
  const { getTranslate } = useContext(LanguageContext)
  return (
    <div>
      <div
        id="banner"
        className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
        data-src={article.image.publicURL}
        data-srcset={article.image.publicURL}
        data-uk-img
      >
        <h1>{getTranslate(article.title.translate)}</h1>
      </div>
      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <ReactMarkdown source={getTranslate(article.content.translate)}/>
          <p>
            <Moment format="DD-MM-YYYY">{article.published_at}</Moment>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Article
